import React from 'react';
import { HeroBanner } from '@hpstellar/core';
import HeroBannerVisID from '@hpstellar/core/visId/HeroBanner';
import withPageComponents from '../../../page/components/with-page-components';
import { getHeroBannerContent, getHeroBannerSlides } from '../../../utility/setHtml';
import './hero-banner.less';

// to be removed as part of clean later
const isDataNew = banner => {
    const { contentAlignment, cta, textTheme, image } = banner;

    if (contentAlignment || cta || textTheme || image) {
        return false;
    }
    return true;
};

const heroBanner = ({ categoryHeroBanner }) => {
    if ((categoryHeroBanner || []).length < 1) return null;

    // check if it is old herobanner data - to be removed as part of clean
    if (isDataNew(categoryHeroBanner[0])) {
        const slides = getHeroBannerSlides(categoryHeroBanner);
        return <HeroBannerVisID className="visIdCategoryHeroBanner" slides={slides} />;
    } else {
        const singleSlide = categoryHeroBanner.length === 1;
        return (
            <HeroBanner
                className={`categoryHeroBanner ${singleSlide ? ' singleSlide' : ''}`}
                banners={getHeroBannerContent(categoryHeroBanner)}
            />
        );
    }
};

export default withPageComponents(heroBanner, { components: ['categoryHeroBanner'] });
